import React from 'react';
import '../styles/main.scss';
import DynamicAsset from 'components/assets/DynamicAsset';

/**
 * Show a preview of the Affectivity video
 */
class AffectivityPreview extends React.Component {

    onMutation = (data, type = 'model') => {
        this.props.onMutation(data, type);
    }

    render() {
        const { poster, format = '', value } = this.props;

        let height = 200;
        if (format == 'square') {
            height = 320;
        }
        else if (format == 'vertical') {
            height = 568;
        }

        return (
            <div className="affectivity-preview">
                <div className={'video-wrapper ' + format}>
                    <DynamicAsset
                        height={height}
                        key={'affectivity_item'}
                        type="dynamicVideo"
                        data={this.props.affectivity}
                        value={value}
                        poster={poster}
                        onMutation={this.onMutation}
                    />
                </div>
            </div>
        )
    }
}

export default AffectivityPreview
