import AffectivityPreview from './components';
import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';

const AffectivityPreviewDynamicData = EditorDynamicDataWrapper(AffectivityPreview);

export default AffectivityPreviewDynamicData;
export {
    AffectivityPreview,
    AffectivityPreviewDynamicData
}
